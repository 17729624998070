import { BKJButtonIconTextInput, BKJTextInput } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { EmploymentStatusFilterInput } from 'components/EmploymentStatusFilterInput';
import { Flex } from 'components/Flex';
import { JaneGridSelect } from 'components/JaneGridSelect';
import { QuerySwitch } from 'components/QuerySwitch';
import { RequestTeamMemberFilterBar } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberFilterBar';
import { UserGuard } from 'guards/UserGuard';
import { CreateShiftCardContext } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.context';
import { FC, Fragment, MouseEventHandler, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { calculateShiftDurationInMinutes } from 'utils/time';
import { withMemo } from 'utils/withMemo';
import {
  MainTitle,
  RequestedJanesBoldText,
  RequestedJanesText,
  TotalAvailableJanesText,
} from '../CreateShiftModal.styled';
import {
  RequestTeamProvider,
  RequestTeamsContext,
  useRequestTeamViewContext,
} from './RequestTeamView.context';
import {
  HeaderWrapper,
  MainTitleContainer,
  ResetButtonLabel,
  ResetButtonWrapper,
  SearchTeamMembersWrapper,
  StyledJanesWrapper,
  BKJSideModalFooterContainer,
  BKJSideModalFooterContent,
  BKJSideModalFooterConfirmationButton,
} from './RequestTeamView.styled';
import { useResponsive } from 'providers/ResponsiveProvider/Responsive';
import { FormatCurrency } from 'components/FormatCurrency';

const RenderSSFControls: FC = () => {
  const { t } = useTranslation();
  const { values, onChange, onReset } = useRequestTeamViewContext();

  const [isOpen, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <Flex flexDirection="column">
      <SearchTeamMembersWrapper>
        <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
          <BKJButtonIconTextInput
            placeholder={t('common:SEARCH_MEMBER')}
            buttonLabel={t('common:FILTERS')}
            name="byFullName"
            iconName="Search"
            buttonIcon="Filters"
            value={values.byFullName}
            onChange={onChange}
            onButtonClick={handleClick}
            autoComplete="off"
          />
        </UserGuard>
        <UserGuard userType={['USER_TYPE_ESSENTIAL', 'USER_TYPE_ESSENTIAL_SECONDARY']}>
          <BKJTextInput
            placeholder={t('common:SEARCH_MEMBER')}
            name="byFullName"
            leftIconProps={{ iconName: 'Search' }}
            value={values.byFullName}
            onChange={onChange}
            autoComplete="off"
          />
        </UserGuard>
      </SearchTeamMembersWrapper>

      <RequestTeamMemberFilterBar isOpen={isOpen}>
        <Flex justifyContent="space-between" width="100%" alignItems="baseline">
          <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
            <EmploymentStatusFilterInput
              name="byEmploymentStatus"
              onChange={onChange}
              value={values.byEmploymentStatus}
              options={['casual', 'full_time', 'part_time', 'flex']}
            />
          </UserGuard>
          {/* Commenting out the Agency Filter. Scoped out for J360 V1. Because API wasn't ready. */}
          {/* <UserGuard userType={['USER_TYPE_ESSENTIAL', 'USER_TYPE_ESSENTIAL_SECONDARY']}>
            <AgencyFilterInput
              dropdownVariant="Square"
              placeholder="Agency"
              type="Multiple"
              variant="GreyOutlinedPillClear"
              name="byAgency"
              onChange={onChange}
              value={values.byAgency}
            />
          </UserGuard> */}
          <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
            <ResetButtonWrapper onClick={onReset as MouseEventHandler<HTMLDivElement>}>
              <ResetButtonLabel>{t('common:RESET')}</ResetButtonLabel>
            </ResetButtonWrapper>
          </UserGuard>
        </Flex>
      </RequestTeamMemberFilterBar>
    </Flex>
  );
};

function RenderJanes() {
  const { handleChange, selectedJanes, loadMore } = useRequestTeamViewContext();

  const { createShift } = useContext(CreateShiftCardContext);
  const {
    values: { quantity },
  } = createShift;

  return (
    <JaneGridSelect
      edgeCaseType="CreateBulkShifts"
      paginationType="InfiniteScroll"
      type={parseInt(quantity[0]) > 1 ? 'Multiple' : 'Single'}
      max={parseInt(quantity[0])}
      name="request_janes"
      dataPath="requestJanes.nodes"
      value={selectedJanes}
      context={RequestTeamsContext}
      onChange={handleChange}
      loadMore={loadMore}
      pageInfoDataPath="requestJanes.pageInfo"
    />
  );
}

const RequestTeamMembersComponent = () => {
  const { t } = useTranslation();
  const { createShift } = useContext(CreateShiftCardContext);
  const { isMobile } = useResponsive();
  const {
    values: { quantity, startDate, startAt, endAt, unpaidBreakTime },
  } = createShift;

  const {
    data: fetchJanesData,
    isLoading,
    handleSaveAndClose,
    selectedJanes,
    actionableTitle,
  } = useRequestTeamViewContext();

  const estimatedPayout =
    fetchJanesData?.requestJanes?.nodes?.reduce((accum: number, curr) => {
      if (selectedJanes.includes(curr.id)) {
        const janeRateRaw = curr.positions[0].agencyDetails?.openRate;
        return accum + Number.parseFloat(janeRateRaw?.replace('$', ''));
      }
      return accum;
    }, 0) || 0;

  const durationMinutes = calculateShiftDurationInMinutes({
    startDate: startDate,
    startAt: startAt,
    endAt: endAt,
    unpaidBreakTime: unpaidBreakTime,
    isPaidDuration: true,
  });

  const durationHours = durationMinutes / 60;

  const estimatedTotalPayout = durationHours * estimatedPayout;

  return (
    <Fragment>
      <HeaderWrapper flexDirection="column">
        <MainTitleContainer justifyContent="space-between" alignItems="baseline">
          <MainTitle>{actionableTitle}</MainTitle>
          <TotalAvailableJanesText>
            {!isLoading &&
              fetchJanesData &&
              `${fetchJanesData.requestJanes?.totalCount} ${t('common:AVAILABLE')}`}
          </TotalAvailableJanesText>
        </MainTitleContainer>
        <RenderSSFControls />
      </HeaderWrapper>
      <StyledJanesWrapper width="100%" flex="1" padding="0px 24px 0px 24px">
        <QuerySwitch component={RenderJanes} context={RequestTeamsContext} />
      </StyledJanesWrapper>
      <BKJSideModalFooter>
        <BKJSideModalFooterContainer
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <BKJSideModalFooterContent flexDirection="column">
            <div>
              <RequestedJanesBoldText>{t('common:REQUESTED')}:&nbsp;</RequestedJanesBoldText>
              <RequestedJanesText>
                {selectedJanes.length}/{quantity[0]}
              </RequestedJanesText>
            </div>
            <UserGuard userType={['USER_TYPE_ESSENTIAL', 'USER_TYPE_ESSENTIAL_SECONDARY']}>
              <div>
                <RequestedJanesBoldText>
                  {t('common:ESTIMATED_TOTAL_PAY')}:&nbsp;
                </RequestedJanesBoldText>
                <RequestedJanesText>
                  <FormatCurrency amount={`${estimatedTotalPayout}`} />
                </RequestedJanesText>
              </div>
            </UserGuard>
          </BKJSideModalFooterContent>
          <BKJSideModalFooterConfirmationButton
            variant={isMobile ? 'PurpleSolid' : 'GreenSolid'}
            onClick={handleSaveAndClose}
          >
            {t('auth:SAVE')}
          </BKJSideModalFooterConfirmationButton>
        </BKJSideModalFooterContainer>
      </BKJSideModalFooter>
    </Fragment>
  );
};

function _RequestTeamMembers() {
  return (
    <RequestTeamProvider>
      <RequestTeamMembersComponent />
    </RequestTeamProvider>
  );
}

export const RequestTeamMembers = withMemo(_RequestTeamMembers);
