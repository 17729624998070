import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { buildTotalEstimatedPayoutRange } from '../../SendToAgenciesSummaryView.constants';
import { ShiftPay } from '..';

function OpenPayComponent() {
  const { data: fetchOrderData } = useShiftModalDataContext();

  const allOpenEstimatedTotalPayout = buildTotalEstimatedPayoutRange(
    fetchOrderData.fetchOrder!.position!.openPriceRange!,
    fetchOrderData.fetchOrder!.totalDuration!,
  );

  return (
    <ShiftPay
      estimatedHourlyPay={fetchOrderData.fetchOrder!.position.openPriceRange?.rangeLabel!}
      estimatedTotalPay={allOpenEstimatedTotalPayout}
    />
  );
}

export function OpenPay() {
  return <OpenPayComponent />;
}
