import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { SendToAgenciesFormProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { SendToAgenciesPropsProvider } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.props.context';
import { AuthRouteDataProvider } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC } from 'react';

const SendToAgenciesViewComponent: FC = () => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const { data } = useShiftModalDataContext();
  if (!data?.fetchOrder!.canSendToAgency) shiftModalRoutingService.goToView('DetailView');
  const Component = ShiftModalComponentMap[shiftModalActiveModalView];
  return <Component />;
};

export const SendToAgenciesViewRenderer: FC = () => {
  return (
    <AuthRouteDataProvider>
      <SendToAgenciesFormProvider>
        <SendToAgenciesPropsProvider>
          <SendToAgenciesViewComponent />
        </SendToAgenciesPropsProvider>
      </SendToAgenciesFormProvider>
    </AuthRouteDataProvider>
  );
};
