import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_PATCH_approveTimeSheet, API_PATCH_noShowTimeSheet } from 'requests/PATCH_TimeSheet';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { createGQLDataContext } from 'utils/createDataContext';
import { useTimeSheetsTabDataContext } from './TimesheetsTabData.context';
import { useTranslation } from 'hooks/useTranslation';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { DateTime } from 'luxon';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';

export const TimesheetsTabContext = createGQLDataContext({});

export const TimesheetsTabProvider: FC = ({ children }) => {
  const { timeZone } = getCurrentlySelectedLocation();
  const { data, fetch } = useTimeSheetsTabDataContext();
  const { fetch: generalTabFetch } = useShiftModalDataContext();
  const { refreshBackgroundPage } = useShiftModalPropsContext();
  const { apiRequest } = useAPIActions();
  const [approvalFormValues, setApprovalFormValues] = useState({
    startTimeHrs: [
      DateTime.fromISO(data?.fetchTimeSheet?.timeSheet?.clockIn).setZone(timeZone).toFormat('HH'),
    ],
    startTimeMins: [
      DateTime.fromISO(data?.fetchTimeSheet?.timeSheet?.clockIn).setZone(timeZone).toFormat('mm'),
    ],
    endTimeHrs: [
      DateTime.fromISO(data?.fetchTimeSheet?.timeSheet?.clockOut).setZone(timeZone).toFormat('HH'),
    ],
    endTimeMins: [
      DateTime.fromISO(data?.fetchTimeSheet?.timeSheet?.clockOut).setZone(timeZone).toFormat('mm'),
    ],
    unpaidBreakHrs: [
      !!data?.fetchTimeSheet?.timeSheet?.unpaidBreakTime
        ? data.fetchTimeSheet.timeSheet.unpaidBreakTime.split(':')[0]
        : '00',
    ],
    unpaidBreakMins: [
      !!data?.fetchTimeSheet?.timeSheet?.unpaidBreakTime
        ? data.fetchTimeSheet.timeSheet.unpaidBreakTime.split(':')[1]
        : '00',
    ],
  });

  const { t } = useTranslation();

  const handleFormChange = (event: ChangeEventType) => {
    setApprovalFormValues({
      ...approvalFormValues,
      [event?.target.name]: event?.target.value,
    });
  };

  const requestOptions = () =>
    API_PATCH_approveTimeSheet({
      id: data!.fetchTimeSheet.timeSheet!.id,
      endTime: `${approvalFormValues.endTimeHrs[0]}:${approvalFormValues.endTimeMins[0]}`,
      startTime: `${approvalFormValues.startTimeHrs[0]}:${approvalFormValues.startTimeMins[0]}`,
      unpaidBreak: `${approvalFormValues.unpaidBreakHrs[0]}:${approvalFormValues.unpaidBreakMins[0]}`,
    });

  const requestOptionsNoShow = () =>
    API_PATCH_noShowTimeSheet({
      id: data!.fetchTimeSheet.timeSheet!.id,
    });

  const onApprovalSubmit = async () => {
    apiRequest(requestOptions(), {
      onSuccess: async () => {
        toastService.success(t('success:TIMESHEET_APPROVED'));
        refreshBackgroundPage();
        generalTabFetch();
        await fetch();
      },
      onError: (e) => handleGenericErrorMessage(e, t('errors:ERROR_APPROVING_TIMESHEET')),
    });
  };

  const onMarkAsNoShow = async () => {
    apiRequest(requestOptionsNoShow(), {
      onSuccess: async () => {
        toastService.success(t('success:TIMESHEET_NO_SHOW'));
        refreshBackgroundPage();
        generalTabFetch();
        await fetch();
      },
      onError: (e) => handleGenericErrorMessage(e, t('errors:ERROR_UPDATING_TIMESHEET')),
    });
  };

  const request = useSelector((state) => apiDataSelector(state, requestOptions()));

  return (
    <TimesheetsTabContext.Provider
      value={{
        ...request,
        onApprovalSubmit,
        onMarkAsNoShow,
        values: approvalFormValues,
        onChange: handleFormChange,
      }}
    >
      {children}
    </TimesheetsTabContext.Provider>
  );
};

export const useTimesheetsTabFormContext = () => useContext(TimesheetsTabContext);
