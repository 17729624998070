import {
  BKJButton,
  BKJCheckbox,
  BKJIcon,
  BKJTextArea,
  BKJToggleInput,
} from '@bookjane2/bookjane-design-library';
import { useTranslation } from 'hooks/useTranslation';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { ShiftCustomFieldInput } from 'components/ShiftModal/src/components/ShiftCustomFieldInput/ShiftCustomFieldInput';
import { NotifyTeamMember } from 'components/ShiftModal/src/views';
import {
  ShiftStatusText,
  ShiftStatusDot,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import {
  EditViewProvider,
  useEditViewContext,
} from 'components/ShiftModal/src/views/EditView/EditView.context';
import {
  CustomFieldsFormLabel,
  FormLabel,
  PositionText,
  ValueText,
  WarningText,
} from 'components/ShiftModal/src/views/EditView/EditView.styled';
import { ShiftTimeSelectInput } from 'components/ShiftTimeSelectInput';
import { ShiftUnpaidBreakTimeSelectInput } from 'components/ShiftUnpaidBreakTimeSelectInput';
import { H3 } from 'components/Typography';
import { FeatureGuard } from 'guards/FeatureGuard';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { MobileLabel } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView.styled';
import { FC, Fragment, MouseEventHandler, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OrderStatusEnum } from 'types/graphql-types';
import { withMemo } from 'utils/withMemo';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import schedulingReadAccessFacilityIdsSelector from 'store/selectors/scheduleSelectors/schedulingReadAccessFacilityIdsSelector';
import { ConfirmEditsView } from 'components/ShiftModal/src/views/ConfirmEditsView';
import { ReasonForEdits } from 'components/ShiftModal/src/views/ReasonForEdits';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';
import { AutoMargin } from 'components/AutoMargin';
import { DateTime } from 'luxon';
import { EditingReasonAndNotifyView } from 'components/ShiftModal/src/views/DetailView/GeneralTab/ReasonForEditingAndNotify';

export const shiftModalEditViewFormItemVerticalMargin = 24;

export const EditView: FC = withMemo(() => {
  const { t, i18n } = useTranslation();
  const { data } = useShiftModalDataContext();
  const { goToConfirmEditsView } = useShiftModalPropsContext();

  const communityCustomFields = data.fetchOrder.community.customFields || [];

  const isCustomFieldsEnabled =
    useSelector(FEATURE_SELECTORS_MAP['FEATURE_TYPE_CUSTOM_FIELDS']) &&
    communityCustomFields.length > 0;

  const {
    isDisabled,
    isEditInProgressShift,
    isEditPostShift,
    isSubmitting,
    onChange,
    isNotifyFlowRequired,
    totalDuration,
    totalPaidDuration,
    values,
    fieldErrors,
  } = useEditViewContext();
  const { value: orderStatusValue } = data.fetchOrder.status;

  const locationsWithScheduling = useSelector(schedulingReadAccessFacilityIdsSelector);
  const isSchedulingEnabled = useMemo(
    () => locationsWithScheduling.includes(Number(data.fetchOrder.community.id)),
    [data.fetchOrder.community.id, locationsWithScheduling],
  );

  const isAllowOvertimeEnabled =
    !data?.fetchOrder?.recurrence &&
    !data?.fetchOrder?.allowOvertime &&
    orderStatusValue === OrderStatusEnum.pending &&
    isSchedulingEnabled;

  const goToDeleteView = useCallback(() => {
    shiftModalRoutingService.goToView('DeleteView');
  }, []);

  const GetEditingReasonAndNotifyFlow = useCallback(() => {
    switch (orderStatusValue) {
      case OrderStatusEnum.assigned:
      case OrderStatusEnum.accepted:
      case OrderStatusEnum.swapped:
        return isNotifyFlowRequired
          ? shiftModalRoutingService.goToView('EditingReasonAndNotifyView')
          : goToConfirmEditsView();
      case OrderStatusEnum.in_progress:
      case OrderStatusEnum.completed:
        return shiftModalRoutingService.goToView('EditingReasonAndNotifyView');
      default:
        return goToConfirmEditsView();
    }
  }, [orderStatusValue, isNotifyFlowRequired, goToConfirmEditsView]);

  const handleSubmit = useCallback(
    (event: MouseEventHandler<HTMLButtonElement> & { preventDefault: () => void }) => {
      event.preventDefault();
      return GetEditingReasonAndNotifyFlow();
    },
    [GetEditingReasonAndNotifyFlow],
  );

  const allowShiftEditOneDayFuturePastFromStartDate =
    isEditInProgressShift || isEditPostShift
      ? {
          disabledFrom: DateTime.fromISO(data.fetchOrder?.startDate).plus({ day: 1 }).toString(),
          disabledTo: DateTime.fromISO(data.fetchOrder?.startDate).minus({ day: 1 }).toString(),
        }
      : undefined;

  return (
    <Fragment>
      <Flex flexDirection="column" padding="32px" flex="1">
        <H3 color="TextDarkGrey">{t('shift_modal:EDIT_SHIFT')}</H3>

        <Flex padding="24px 0" alignItems="center">
          <ShiftStatusDot type={data.fetchOrder!.status.name.toLowerCase()}>•</ShiftStatusDot>
          <ShiftStatusText>
            {getTranslatedResponse(data.fetchOrder!.status.name, 'status', i18n, t)}
          </ShiftStatusText>
        </Flex>

        <Flex
          flexDirection="column"
          margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}
          gap="12px"
        >
          <PositionText>{t('shift_modal:POSITION')}</PositionText>
          <ValueText>{data.fetchOrder!.position.name}</ValueText>
        </Flex>

        <Flex flexDirection="column" margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}>
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <FormLabel>{t('shift_modal:DATE')}</FormLabel>

            <ShiftDateCalendarInput
              name="start_date"
              iconComponent={BKJIcon}
              iconProps={{
                iconName: 'ShiftsDate',
              }}
              value={values.start_date}
              onChange={onChange}
              disabled={values.zeroHoursWorked}
              dropdownProps={allowShiftEditOneDayFuturePastFromStartDate}
            />
          </Flex>
        </Flex>

        <Flex width="100%" margin="0 0 24px">
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <ShiftTimeSelectInput
              label={t('shift_modal:START_TIME')}
              placeholder={t('shift_modal:START_TIME')}
              onChange={onChange}
              name="start_time"
              value={values.start_time}
              error={fieldErrors?.start_time}
              disabled={values.zeroHoursWorked}
            />
          </Flex>

          <Flex flexDirection="column" width="50%" padding="0 0 0 8px">
            <ShiftTimeSelectInput
              label={t('shift_modal:END_TIME')}
              placeholder={t('shift_modal:END_TIME')}
              onChange={onChange}
              name="end_time"
              value={values.end_time}
              error={fieldErrors?.end_time}
              disabled={values.zeroHoursWorked}
            />
          </Flex>
        </Flex>

        <Flex width="100%" margin="0 0 24px">
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <ShiftUnpaidBreakTimeSelectInput
              label={t('shift_modal:UNPAID_BREAK')}
              width="100%"
              placeholder={t('shift_modal:UNPAID_BREAK')}
              onChange={onChange}
              name="unpaid_break_time"
              value={values.unpaid_break_time}
              error={fieldErrors?.unpaid_break_time}
              disabled={values.zeroHoursWorked}
            />
          </Flex>
        </Flex>

        <Flex width="100%" margin="0 0 24px">
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <FormLabel>{t('shift_modal:TOTAL_DURATION')}</FormLabel>
            <ValueText>{totalDuration}</ValueText>
          </Flex>

          <Flex flexDirection="column" width="50%" padding="0 0 0 8px">
            <FormLabel>{t('shift_modal:TOTAL_PAID_DURATION')}</FormLabel>
            <ValueText
              isError={
                !!fieldErrors?.start_time ||
                !!fieldErrors?.end_time ||
                !!fieldErrors?.unpaid_break_time
              }
            >
              {values.zeroHoursWorked ? '0 hrs' : totalPaidDuration}
            </ValueText>
          </Flex>
        </Flex>
        {(isEditInProgressShift || isEditPostShift) && (
          <Flex flexDirection="column" width="100%" margin="0 0 24px">
            <Flex alignItems="center">
              <Flex flexDirection="column" padding="0 8px 0 0">
                <BKJCheckbox
                  variant={'GreenSolid'}
                  name="zeroHoursWorked"
                  value={values.zeroHoursWorked}
                  onChange={onChange}
                />
              </Flex>
              <Flex flexDirection="column" justifyContent="center">
                <ValueText>{t('shift_modal:ABSENT')}</ValueText>
              </Flex>
            </Flex>
            <WarningText marginTop={8}>{t('shift_modal:ABSENT_WARNING')}</WarningText>
          </Flex>
        )}
        <FeatureGuard type="FEATURE_TYPE_SCHEDULING">
          <Flex margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`} alignItems="center">
            <ValueText>{t('shift_modal:ALLOW_OVERTIME')}</ValueText>
            <BKJToggleInput
              variant="Switch"
              name="allow_overtime"
              disabled={!isAllowOvertimeEnabled}
              onChange={onChange}
              checked={values.allow_overtime}
              css="margin-left: 16px;"
            />
          </Flex>
        </FeatureGuard>

        <Flex flexDirection="column">
          <FormLabel>{t('shift_modal:SHIFT_NOTES')}</FormLabel>
          <BKJTextArea
            name="description"
            placeholder={t('shift_modal:TYPE_NOTES')}
            value={values.description}
            onChange={onChange}
            maxLength={2000}
          />
        </Flex>
        {isCustomFieldsEnabled && (
          <Fragment>
            <SectionDivider
              variant="Thin"
              margin={`${shiftModalEditViewFormItemVerticalMargin}px 0`}
            />
            <CustomFieldsFormLabel>{t('shift_modal:CUSTOM_FIELDS')}</CustomFieldsFormLabel>
            {communityCustomFields?.map((customField) => {
              return (
                <Flex
                  key={customField.id}
                  flexDirection="column"
                  margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}
                >
                  <FormLabel>
                    {customField.name}
                    {customField.mobile && (
                      <MobileLabel>
                        &nbsp;({t('shift_modal:SHOWN_INTERNALLY_ON_MOBILE')})
                      </MobileLabel>
                    )}
                  </FormLabel>
                  <ShiftCustomFieldInput
                    key={customField.id}
                    customField={customField}
                    value={values[customField.name] as string}
                    onChange={onChange}
                  />
                </Flex>
              );
            })}
          </Fragment>
        )}
      </Flex>

      <BKJSideModalFooter justifyContent="space-between">
        <BKJButton width="auto" variant="RedText" onClick={goToDeleteView}>
          {t('shift_modal:DELETE')}
        </BKJButton>
        <AutoMargin />
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          disabled={isDisabled}
          isLoading={isSubmitting}
          type="button"
          onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
        >
          {t('shift_modal:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
});

export const EditViewRenderer: FC = withMemo((props) => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const Component = {
    ...ShiftModalComponentMap,
    EditView,
    NotifyTeamMember,
    ReasonForEdits,
    ConfirmEditsView,
    EditingReasonAndNotifyView,
  }[shiftModalActiveModalView];
  return (
    <EditViewProvider>
      <Component {...props} />
    </EditViewProvider>
  );
});
