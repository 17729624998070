import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import { useShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { AssignToAgencyTeamMemberFormProvider } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/AssignToAgencyTeamMemberView.form.context';
import { FC } from 'react';

const AssignToAgencyTeamMemberViewComponent: FC = () => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const Component = ShiftModalComponentMap[shiftModalActiveModalView];

  return <Component />;
};

export const AssignToAgencyTeamMemberViewRenderer: FC = () => {
  return (
    <AssignToAgencyTeamMemberFormProvider>
      <AssignToAgencyTeamMemberViewComponent />
    </AssignToAgencyTeamMemberFormProvider>
  );
};
