import { BKJLoadingSpinner } from '@bookjane2/bookjane-design-library';
import { IBKJLoadingSpinnerProps } from '@bookjane2/bookjane-design-library/lib/components/BKJLoadingSpinner/BKJLoadingSpinner.types';
import { Flex } from 'components/Flex';
import { RenderError } from 'components/QuerySwitch/RenderError';
import { ComponentType, Fragment, useContext } from 'react';
import { MountTransition } from 'routes';
import { GQLAPIContextType, isReactComponent, isReactElement } from 'types/common.types';

const getComponent = ({
  loadingComponent: LoadingComponent,
  errorComponent,
  loadingIconSize,
  component: Component,
  values,
}: {
  loadingComponent: ComponentType;
  loadingIconSize: IBKJLoadingSpinnerProps['variant'];
  component: ComponentType<unknown>;
  errorComponent?: ComponentType;
  values: Record<string, unknown>;
  context: GQLAPIContextType;
}) => ({
  ERROR: <RenderError errorComponent={errorComponent} />,
  READY: isReactComponent(Component) ? (
    <Component {...values} />
  ) : isReactElement(Component) ? (
    <Fragment>{Component}</Fragment>
  ) : (
    <Fragment />
  ),
  LOADING: LoadingComponent ? (
    <LoadingComponent />
  ) : (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      flex="1"
      justifyContent="center"
      alignItems="center"
    >
      <BKJLoadingSpinner variant={loadingIconSize} />
    </Flex>
  ),
});

export function GraphQLAPISwitch<T = unknown>({
  context,
  loadingComponent,
  loadingIconSize,
  errorComponent,
  component,
  height,
  className,
  isTransitionDisabled = false,
  ...rest
}: {
  component: ComponentType<unknown>;
  errorComponent?: ComponentType;
  loadingComponent: ComponentType<{}>;
  loadingIconSize: IBKJLoadingSpinnerProps['variant'];
  context: GQLAPIContextType<T>;
  height: string;
  className?: string;
  isTransitionDisabled?: boolean;
}) {
  const values = useContext<GQLAPIContextType<T>>(context);
  const { status } = values;
  const _key = `${status}-${values.key}`;
  const element = getComponent({
    loadingComponent,
    loadingIconSize,
    errorComponent,
    component,
    values,
    context,
    ...rest,
  })[status];
  if (isTransitionDisabled) return <Fragment>{element}</Fragment>;
  return (
    <MountTransition key={_key} height={height} className={className}>
      {element}
    </MountTransition>
  );
}
