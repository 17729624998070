import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { requestTeamMemberConfig } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberViewDataProvider.constants';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useSSFBehaviors } from 'hooks';
import { SSFBehaviorsContextType } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC, useContext, useMemo } from 'react';
import { requestTeamMembersRecurring, requestTeamMembers } from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

export const FetchTeamMembersDataContext = createGQLDataContext<
  SSFBehaviorsContextType<requestTeamMembersRecurring & requestTeamMembers>
>({} as SSFBehaviorsContextType<requestTeamMembersRecurring & requestTeamMembers>);

export const FetchTeamMembersDataProvider: FC = ({ children }) => {
  const { data: fetchOrderData } = useShiftModalDataContext();
  const { values: formValues } = useSendToAgenciesFormContext();

  const {
    locationFilter: { initialValue: facilityId },
  } = useAuthRouteDataContext();

  const [values, rest] = useSSFBehaviors(
    requestTeamMemberConfig({
      facilityId: Number(facilityId.value),
      isDedicated: formValues.isDedicated,
      orderId: fetchOrderData.fetchOrder!.relayId,
      positionId: fetchOrderData.fetchOrder!.position.id,
      communityId: fetchOrderData.fetchOrder!.community.id,
      recurring: fetchOrderData.fetchOrder!.recurrence
        ? {
            startTime: fetchOrderData.fetchOrder!.startAt,
            endTime: fetchOrderData.fetchOrder!.endAt,
            frequency: fetchOrderData.fetchOrder!.recurrence?.frequency!,
            interval: fetchOrderData.fetchOrder!.recurrence?.interval!,
            expirationDate: fetchOrderData.fetchOrder!.recurrence?.expirationDate,
          }
        : undefined,
    }),
  );

  const contextValue = useMemo(() => ({ values, ...rest }), [rest, values]);

  return (
    <FetchTeamMembersDataContext.Provider value={contextValue}>
      {children}
    </FetchTeamMembersDataContext.Provider>
  );
};

export const useFetchTeamMembersDataContext = (): SSFBehaviorsContextType<
  requestTeamMembersRecurring & requestTeamMembers
> => useContext(FetchTeamMembersDataContext);
