import { Fragment } from 'react';
import { BKJBadge, BKJIcon } from '@bookjane2/bookjane-design-library';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Flex } from 'components/Flex';
import { IShiftJane } from 'components/ShiftModal/src/components/ShiftJane/ShiftJane.types';
import { UserGuard } from 'guards/UserGuard';
import { getShiftModalShiftJaneName } from 'mappers/shiftModalOrderDetailsMapper';
import { css } from 'styled-components';
import { Jane, JaneName, JaneFacility, ShiftJaneWrapper } from './ShiftJane.styled';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';

export function ShiftJane(props: IShiftJane) {
  const { data, className } = props;
  const { isSharedJanePrimaryCommunityDifferentFromSelectedLocation } = useShiftModalPropsContext();
  return (
    <ShiftJaneWrapper className={className}>
      <Jane justifyContent="space-between" alignItems="center" width="100%">
        <Flex alignItems="center">
          <JaneAvatar
            url={data.fetchOrder!.mainJane!.avatarUrls.small}
            status={data.fetchOrder!.status.value}
            css={css`
              margin-right: 13px;
            `}
            size={30}
            pendingSignup={data.fetchOrder!.mainJane!.pendingSignup}
          />
          <Flex flexDirection="column">
            <Flex alignItems="center">
              <JaneName>{getShiftModalShiftJaneName(data)}</JaneName>
              {isSharedJanePrimaryCommunityDifferentFromSelectedLocation && (
                <Fragment>
                  &nbsp; &nbsp;
                  <BKJIcon iconName="Group2" width={16} height={14} />
                </Fragment>
              )}
            </Flex>
            <JaneFacility>{data.fetchOrder!.mainJane!.communityName}</JaneFacility>
          </Flex>
        </Flex>
        <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
          {!!data.fetchOrder!.mainJane!.agencyCommunity && !data.fetchOrder!.sentToAgencies && (
            <BKJBadge variant="Grey">Agency</BKJBadge>
          )}
        </UserGuard>
      </Jane>
    </ShiftJaneWrapper>
  );
}
