import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';

import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { API_POST_cancelPublishedShiftsById } from 'requests/POST_cancelPublishedShiftsById';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { withMemo } from 'utils/withMemo';
import { DeleteViewFormSchema } from './DeleteView.types';
import { isTimeWithinTwelveHours } from './DeleteView.utils';
import { VALIDATION_cancellationReason } from './validations/VALIDATION_cancellationReason';
import { VALIDATION_specifiedOtherReason } from './validations/VALIDATION_specifiedOtherReason';
import { useTranslation } from 'hooks/useTranslation';
import { handleGenericErrorMessage } from 'utils/handleGenericErrorMessage';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';

export type DeleteViewContextType = {
  showRecurrenceRadioButtons: boolean;
  showDeleteShiftReasonTextArea: boolean;
  showLateWarning: boolean;
};

const deleteViewInitialFormState: DeleteViewFormSchema = {
  cancellationReason: [] as DeleteViewFormSchema['cancellationReason'],
  specifiedOtherReason: '',
  cancelRecurring: 'false',
};

export const DeleteViewContext = createContext(
  {} as UseFormBehaviors<DeleteViewFormSchema> & DeleteViewContextType,
);

export const DeleteViewProvider: FC = withMemo(({ children }) => {
  const { apiRequest } = useAPIActions();
  const { shift_id: shiftId } = useShiftModalUIContext();
  const { data } = useShiftModalDataContext();
  const { refreshBackgroundPage } = useShiftModalPropsContext();

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values) => {
      await apiRequest(API_POST_cancelPublishedShiftsById({ ...values, shiftId }), {
        onSuccess: () => {
          toastService.success(t('success:SHIFT_DELETED'));
          refreshBackgroundPage();
          shiftModalRoutingService.close();
        },
        onError: handleGenericErrorMessage,
      });
    },
    [apiRequest, refreshBackgroundPage, shiftId, t],
  );

  const formBehaviorsOptions = useMemo(
    () =>
      ({
        isDirtyCheckEnabled: true,
        initialState: deleteViewInitialFormState,
        onSubmit: handleSubmit,
        validations: {
          specifiedOtherReason: VALIDATION_cancellationReason,
          cancellationReason: VALIDATION_specifiedOtherReason,
        },
      } as UseFormBehaviors_Options<DeleteViewFormSchema>),
    [handleSubmit],
  );

  const formUtils = useFormBehaviors<DeleteViewFormSchema>(formBehaviorsOptions);

  // Context Values
  const onChange = useCallback((event) => formUtils.onChange(event), [formUtils]);
  const showDeleteShiftReasonTextArea = formUtils.values.cancellationReason[0] === 'other';
  const { startAt, mainJane } = data.fetchOrder!;

  const { timeZone } = getCurrentlySelectedLocation();
  const isShiftAcceptedByAgencyJane = !!mainJane?.agencyCommunity;
  const showLateWarning = !!(
    isTimeWithinTwelveHours(startAt, timeZone) && isShiftAcceptedByAgencyJane
  );

  const value: UseFormBehaviors<DeleteViewFormSchema> & DeleteViewContextType = useMemo(() => {
    return {
      ...formUtils,
      onChange,
      showRecurrenceRadioButtons: !!data.fetchOrder!.recurrence,
      showDeleteShiftReasonTextArea,
      showLateWarning,
    };
  }, [data.fetchOrder, formUtils, onChange, showDeleteShiftReasonTextArea, showLateWarning]);

  return <DeleteViewContext.Provider value={value}>{children}</DeleteViewContext.Provider>;
});

export const useDeleteViewContext = () => useContext(DeleteViewContext);
