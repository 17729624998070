import { useLazyQuery } from '@apollo/client';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { ssfKeysSharingValuesConfig } from 'hooks/useSSFBehaviors/useSSFBehaviors.constants';
import {
  ISSFBehaviorsOptions,
  ISSFBehaviorsQueryResult,
  SSFBehaviorsKeyType,
  SSFBehaviorsSchemaType,
  SSFValueType,
} from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { defaultApolloClient } from 'providers/ApolloProvider';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SessionService } from 'services';
import { ssfPersistenceService } from 'services/ssfPersistenceService';
import { isArray, SSFBehaviorsValueType } from 'types/common.types';
import { identity } from 'utils';
import { captureException } from 'utils/captureException';
import { findValueByKeyDeep } from 'utils/findValueByKeyDeep';
import { getRenderingState } from 'utils/getRenderingState';

function prepareInitialState(
  key: SSFBehaviorsKeyType,
  schema: SSFBehaviorsSchemaType,
  isReset?: boolean,
): SSFBehaviorsValueType {
  const _initialValues: SSFBehaviorsValueType = {};
  Object.entries(schema).forEach(
    ([SSFKey, { initialValue, isGlobalCached = false, isSessionCached }]) => {
      if (typeof initialValue !== 'undefined') _initialValues[SSFKey] = initialValue;

      if (isGlobalCached && !isReset) {
        const cachedValue = ssfPersistenceService.getGlobalCachedSSFValue(SSFKey);

        if (typeof cachedValue !== 'undefined') {
          _initialValues[SSFKey] = cachedValue;
        }
      }

      if (isSessionCached && !isReset) {
        const cachedValue = ssfPersistenceService.getSessionCachedSSFValue(key, SSFKey);
        if (typeof cachedValue !== 'undefined') {
          _initialValues[SSFKey] = cachedValue;
        }
      }
    },
  );

  return _initialValues;
}

function getInitialState({
  key,
  pageSize,
  schema,
  isReset,
}: {
  key: SSFBehaviorsKeyType;
  pageSize: number;
  schema: SSFBehaviorsSchemaType;
  initialValues?: SSFBehaviorsValueType;
  isReset?: boolean;
}) {
  const initialState = prepareInitialState(key, schema, isReset);
  return {
    after: undefined,
    before: undefined,
    last: undefined,
    first: pageSize,
    currentPage: 1,
    ...initialState,
  };
}

function getInitialPaginationState({ pageSize }: { pageSize: number }) {
  return {
    after: undefined,
    before: undefined,
    last: undefined,
    first: pageSize,
    currentPage: 1,
  };
}

export function useSSFBehaviors<TData = any, TVariables = any>(
  config: ISSFBehaviorsOptions,
): [SSFBehaviorsValueType, ISSFBehaviorsQueryResult<TData, TVariables>] {
  const {
    query,
    client = defaultApolloClient,
    schema = {},
    key,
    pageSize = 10,
    payloadTransformer = identity,
    responseTransformer = identity,
    fetchPolicy = 'network-only',
    nextFetchPolicy = 'network-only',
    paginationType = 'PagedPagination',
    debounceTimeout,
    type = 'LocalState',
  } = config;
  const initialState = getInitialState({ key, pageSize, schema });
  const ref: MutableRefObject<SSFBehaviorsValueType> = useRef<SSFBehaviorsValueType>(initialState);

  const debounceRef = useRef<number | null>(null);
  const mountedRef = useRef<boolean>(false);
  const isMounted = mountedRef.current;
  const [, forceUpdate] = useState({});

  const [fetch, { loading, data: dataQuery, ...rest }] = useLazyQuery(query, {
    client,
    notifyOnNetworkStatusChange: false,
    fetchPolicy,
    nextFetchPolicy,
    onCompleted: () => {
      mountedRef.current = true;
      forceUpdate({});
    },
    onError: (error) => {
      captureException(error, {
        tags: {
          file: 'useSSFBehaviors.ts',
          type: 'GraphQLAPIErrorResponse',
        },
        extra: {
          config: {
            query,
          },
        },
      });
    },
  });

  // `data` from useLazyQuery is null after a call to loadMore
  // Cache valid `data` value
  const dataRef = useRef(dataQuery);
  if (dataQuery) dataRef.current = dataQuery;
  const data = dataRef.current;

  const execQuery = useCallback(
    async (shouldCacheReset = false) => {
      if (debounceTimeout) {
        if (debounceRef.current) window.clearTimeout(debounceRef.current);
        debounceRef.current = window.setTimeout(async () => {
          if (shouldCacheReset) {
            dataRef.current = null;
            await client?.cache.reset();
          }
          fetch({ variables: payloadTransformer(ref.current) });
        }, debounceTimeout);
      } else {
        if (shouldCacheReset) {
          dataRef.current = null;
          await client?.cache.reset();
        }
        fetch({ variables: payloadTransformer(ref.current) });
      }
    },
    [client?.cache, debounceTimeout, fetch, payloadTransformer],
  );

  useEffect(() => {
    mountedRef.current = true;
    execQuery(true);

    return () => {
      mountedRef.current = false;
      if (debounceRef.current) {
        window.clearTimeout(debounceRef.current);
      }
    };
  }, [key]);

  const handleChange: ChangeFunctionType = useCallback(
    (event: { target: { name: any; value: any } }) => {
      if (!isArray(event)) {
        const {
          target: { name, value },
        } = event;
        const mapper = schema[name]?.mapper || identity;
        const isGlobalCached = schema[name]?.isGlobalCached;
        const isSessionCached = schema[name]?.isSessionCached;
        const resetFieldsOnChange = schema[name]?.resetFieldsOnChange;

        if (isGlobalCached) {
          ssfPersistenceService.setGlobalCachedSSFValue({ name, value });
        }

        if (isSessionCached) {
          let sessionKeys = [key];
          if (ssfKeysSharingValuesConfig[key]) {
            sessionKeys = sessionKeys.concat(ssfKeysSharingValuesConfig[key]);
          }

          sessionKeys.forEach((sessionKey) => {
            ssfPersistenceService.setSessionCachedSSFValue({
              key: sessionKey,
              name,
              value,
            });
          });
        }

        ref.current[name] = mapper(value);

        if (resetFieldsOnChange?.length) {
          resetFieldsOnChange.map((fieldOption) => {
            const { field, userTypes } = fieldOption;
            const mapper = schema[field]?.mapper || identity;
            const initialValue = schema[field]?.initialValue;
            if (userTypes?.length) {
              if (userTypes.some((userType) => SessionService.assertUserType(userType))) {
                ref.current[field] = mapper(initialValue);
                if (isGlobalCached) {
                  const sessionCache = ssfPersistenceService.getSessionCache();
                  const sessionCacheReset = Object.entries(sessionCache).reduce(
                    (agg: Record<string, Record<string, SSFValueType>>, [key, values]) => {
                      agg[key] = {
                        ...values,
                        [field]: mapper(initialValue),
                      };
                      return agg;
                    },
                    {},
                  );
                  ssfPersistenceService.setSessionCache(sessionCacheReset);
                }
              }
            } else {
              ref.current[field] = mapper(initialValue);
            }
          });
        }

        ref.current.last = undefined;
        ref.current.after = undefined;
        ref.current.before = undefined;
        ref.current.first = pageSize;
        ref.current.currentPage = 1;

        execQuery(true);
        forceUpdate({});
      }
    },
    [execQuery, pageSize, schema],
  );

  const handleReset = useCallback(() => {
    const preservedValues = Object.entries(schema).reduce(
      (acc: Record<string, SSFValueType>, [key, value]) => {
        if (value.isPreservedOnReset) acc[key] = ref.current[key];
        return acc;
      },
      {},
    );
    ref.current = {
      ...getInitialState({ key, pageSize, schema, isReset: true }),
      ...preservedValues,
    };
    Object.entries(schema).forEach(([SSFKey, value]) => {
      if (value.isGlobalCached) {
        ssfPersistenceService.setGlobalCachedSSFValue({
          name: SSFKey,
          value: ref.current[SSFKey],
        });
      }
      if (value.isSessionCached) {
        let sessionKeys = [key];
        if (ssfKeysSharingValuesConfig[key]) {
          sessionKeys = sessionKeys.concat(ssfKeysSharingValuesConfig[key]);
        }
        sessionKeys.forEach((sessionKey) => {
          ssfPersistenceService.setSessionCachedSSFValue({
            key: sessionKey,
            name: SSFKey,
            value: ref.current[SSFKey],
          });
        });
      }
    });

    execQuery(true);
  }, [execQuery]); // eslint-disable-line

  const handleRefetch = useCallback(
    (resetPagination: boolean = false) => {
      if (resetPagination) {
        ref.current = {
          ...ref.current,
          ...getInitialPaginationState({ pageSize }),
        };
      }
      execQuery(true);
    },
    [execQuery],
  );

  const status = useMemo(
    () => getRenderingState(paginationType, !isMounted ? true : loading, rest.error, data),
    [paginationType, data, isMounted, loading, rest.error],
  );

  const loadMore = useCallback(() => {
    if (!loading && data) {
      const endCursor = findValueByKeyDeep('endCursor', data);
      const hasNextPage = findValueByKeyDeep('hasNextPage', data);
      if (hasNextPage) {
        ref.current.last = undefined;
        ref.current.before = undefined;
        ref.current.first = pageSize;
        ref.current.after = endCursor;
        ref.current.currentPage = ref.current.currentPage + 1;

        execQuery();
      }
    }
  }, [data, execQuery, loading, pageSize]);

  const firstPage = useCallback(() => {
    if (!loading && data) {
      ref.current.last = undefined;
      ref.current.before = undefined;
      ref.current.after = undefined;
      ref.current.first = pageSize;
      ref.current.currentPage = 1;

      execQuery(true);
    }
  }, [data, execQuery, loading, pageSize]);
  const nextPage = useCallback(() => {
    const hasNextPage = findValueByKeyDeep('hasNextPage', data);
    const endCursor = findValueByKeyDeep('endCursor', data);
    if (!loading && data) {
      if (hasNextPage) {
        ref.current.last = undefined;
        ref.current.before = undefined;
        ref.current.first = pageSize;
        ref.current.after = endCursor;
        ref.current.currentPage = ref.current.currentPage + 1;

        execQuery(true);
      }
    }
  }, [data, execQuery, loading, pageSize]);
  const lastPage = useCallback(() => {
    const pageCount = findValueByKeyDeep('pageCount', data);
    if (!loading && data) {
      ref.current.last = pageSize;
      ref.current.before = undefined;
      ref.current.first = undefined;
      ref.current.currentPage = pageCount;

      execQuery(true);
    }
  }, [data, execQuery, loading, pageSize]);
  const prevPage = useCallback(() => {
    const hasPreviousPage = findValueByKeyDeep('hasPreviousPage', data);
    const startCursor = findValueByKeyDeep('startCursor', data);
    if (!loading && data) {
      if (hasPreviousPage) {
        ref.current.first = undefined;
        ref.current.after = undefined;
        ref.current.last = pageSize;
        ref.current.before = startCursor;
        ref.current.currentPage = ref.current.currentPage - 1;

        execQuery(true);
      }
    }
  }, [data, execQuery, loading, pageSize]);

  const returnValue: [SSFBehaviorsValueType, ISSFBehaviorsQueryResult] = useMemo(() => {
    return [
      ref.current,
      {
        fetch,
        onChange: handleChange,
        isLoading: !isMounted ? true : loading,
        loadMore,
        firstPage,
        nextPage,
        lastPage,
        prevPage,
        onReset: handleReset,
        onRefetch: handleRefetch,
        currentPage: ref.current.currentPage || null,
        status,
        data: responseTransformer(data),
        ...rest,
        __typename: 'GraphQLAPI',
      },
    ];
  }, [
    data,
    fetch,
    firstPage,
    handleChange,
    handleReset,
    isMounted,
    lastPage,
    loadMore,
    loading,
    nextPage,
    prevPage,
    responseTransformer,
    rest,
    status,
  ]);

  return returnValue;
}
