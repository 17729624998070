import { QuerySwitch } from 'components/QuerySwitch';
import { AvailableMemberTabWrapper } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailabilityMemberTab.styled';
import { AvailableMemberTabTable } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemberTabTable';

import {
  AvailableMembersDataContext,
  AvailableMembersDataProvider,
} from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemeberTab.context';
import { FC } from 'react';
import { ShiftModalErrorView } from 'components/ShiftModal/src/views/ShiftModalErrorView';

export const AvailableMembersTab: FC = () => {
  return (
    <AvailableMemberTabWrapper>
      <AvailableMembersDataProvider>
        <QuerySwitch
          component={AvailableMemberTabTable}
          context={AvailableMembersDataContext}
          errorComponent={ShiftModalErrorView}
        />
      </AvailableMembersDataProvider>
    </AvailableMemberTabWrapper>
  );
};
