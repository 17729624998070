import { BKJTheme, BKJThemeProvider } from '@bookjane2/bookjane-design-library';
import { XtmTheme } from 'providers/ThemeProviders/XtmThemeProvider.theme';
import { App } from 'App';
import { useLogRocket } from 'hooks/useLogRocket';
import { ApolloProvider } from 'providers/ApolloProvider';
import { I18NextProvider } from 'providers/I18NextProvider';
import { StoreProvider } from 'providers/StoreProvider/StoreProvider';
import { FC, StrictMode } from 'react';
import { Router } from 'react-router-dom';
import { history } from 'utils/history';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { ResponsiveProvider } from 'providers/ResponsiveProvider/Responsive';

function getThemeProvider() {
  switch (WhiteLabelAccessor.whiteLabelProvider) {
    case WhiteLabelAccessor.WhiteLabelProvidersEnum.XTM:
      return XtmTheme;
    default:
      return BKJTheme;
  }
}

export const Root: FC = () => {
  useLogRocket();
  return (
    <StrictMode>
      <BKJThemeProvider theme={getThemeProvider()}>
        <StoreProvider>
          <ApolloProvider>
            <I18NextProvider>
              <Router history={history}>
                <ResponsiveProvider>
                  <App />
                </ResponsiveProvider>
              </Router>
            </I18NextProvider>
          </ApolloProvider>
        </StoreProvider>
      </BKJThemeProvider>
    </StrictMode>
  );
};
