export const PAGE_URLS = {
  DASHBOARD: '/dashboard',
  SCHEDULE: '/schedule',
  CREATE_SHIFTS: '/create-shifts',
  SHIFTS: '/shifts',
  TIMESHEETS: '/timesheets',
  TEAM_MEMBER: '/team-member',
  AGENCY_MEMBER: '/agency-member',
  COMMUNICATIONS: '/communications',
  SETTINGS: '/settings',
};
