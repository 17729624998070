import { BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import {
  SendToAgenciesText,
  SendToAgenciesViewTitle,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment, useCallback } from 'react';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';

export const ShiftUnavailableView: FC = () => {
  const { t } = useTranslation();
  const { refreshBackgroundPage } = useShiftModalPropsContext();
  const closeRefreshShiftModal = useCallback(() => {
    refreshBackgroundPage(true);
    shiftModalRoutingService.close();
  }, [refreshBackgroundPage]);

  return (
    <Fragment>
      <Flex flexDirection="column" padding="40px 32px 0px 32px" height="100%">
        <SendToAgenciesViewTitle>
          {t('errors:SHIFT_UNAVAILABLE_VIEW_TITLE')}
        </SendToAgenciesViewTitle>
        <Flex flexDirection="column" gap="18px">
          <SendToAgenciesText>
            {t('errors:SHIFT_UNAVAILABLE_VIEW_DESCRIPTION_1')}
          </SendToAgenciesText>
          <SendToAgenciesText>
            {t('errors:SHIFT_UNAVAILABLE_VIEW_DESCRIPTION_2')}
          </SendToAgenciesText>
        </Flex>
      </Flex>

      <BKJSideModalFooter justifyContent="end">
        <BKJButton width="192px" variant="PurpleSolid" onClick={closeRefreshShiftModal}>
          {t('CLOSE_AND_REFRESH')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
