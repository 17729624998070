import { FC, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import {
  DatePickerWrapper,
  DatePickerNavigationButton,
  DateDisplayButton,
} from './BaseDatePicker.styled';
import { IBaseDatePickerProps } from './BaseDatePicker.types';
import { IBKJDatePickerChangeEvent } from '../../BKJDatePicker.types';
import { mergeRefs } from 'utils/mergeRefs';
import { createPortal } from 'react-dom';
import { useClickOutside } from 'hooks';
import { usePopper } from 'react-popper';
import { getJ360ElementNode } from 'utils/getJ360ElementNode';
import { BKJDatePickerContext } from '../../BKJDatePicker.context';
import { popperModifiers } from '../../BKJDatePicker.constants';
import { CalendarPopUp } from '../../components';
import { DROPDOWN_ROOT_ID } from 'constants/elements';

export const BaseDatePicker: FC<IBaseDatePickerProps> = forwardRef((props, ref): JSX.Element => {
  const { name, nextValue, previousValue, displayDate, onChange, variant } = props;

  const popupRef = useRef<HTMLElement | null>(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(
    getJ360ElementNode(DROPDOWN_ROOT_ID),
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: popperModifiers,
    placement: 'top-start',
  });

  const handlePrevious = useCallback((): void => {
    const e: IBKJDatePickerChangeEvent = {
      target: {
        name,
        value: previousValue,
      },
    };
    return onChange(e);
  }, [name, onChange, previousValue]);

  const handleNext = useCallback((): void => {
    const e: IBKJDatePickerChangeEvent = {
      target: {
        name,
        value: nextValue,
      },
    };
    return onChange(e);
  }, [name, nextValue, onChange]);

  function handleCalendarPopUpClose() {
    if (isCalendarOpen) setIsCalendarOpen(false);
  }

  useEffect(() => {
    if (!targetElement) {
      const element = document.createElement('div');
      element.setAttribute('id', DROPDOWN_ROOT_ID);
      document.body.appendChild(element);
      setTargetElement(element);
    }
  }, [targetElement]);

  useClickOutside(
    [
      popupRef && popupRef.current,
      targetElement && targetElement,
      referenceElement && referenceElement,
    ],
    handleCalendarPopUpClose,
  );

  return (
    <DatePickerWrapper variant={variant}>
      <DatePickerNavigationButton onClick={handlePrevious} tabIndex={0}>
        <BKJIcon iconName="ArrowLeft" color="PrimaryDefault" />
      </DatePickerNavigationButton>
      <DateDisplayButton
        isOpen={isCalendarOpen}
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        ref={setReferenceElement}
      >
        {displayDate}
      </DateDisplayButton>
      {isCalendarOpen &&
        !!targetElement &&
        createPortal(
          <CalendarPopUp
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalendarOpen}
            context={BKJDatePickerContext}
            ref={mergeRefs([popupRef, setPopperElement])}
            style={styles.popper}
            {...attributes.popper}
          />,
          targetElement,
        )}
      <DatePickerNavigationButton onClick={handleNext}>
        <BKJIcon iconName="ArrowRight" color="PrimaryDefault" />
      </DatePickerNavigationButton>
    </DatePickerWrapper>
  );
});
