import { BulkCreateShiftView } from 'pages/CreateShiftsPage/BulkCreateShiftView';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import {
  CreateShiftsCardErrorMessagesType,
  ICreateShiftsPageContext,
} from 'pages/CreateShiftsPage/CreateShiftsPage.types';
import {
  getInitialCreateShiftForm,
  getInitialCreateShiftsPageForm,
} from 'pages/CreateShiftsPage/CreateShiftsPage.utils';
import { ShiftSummaryView } from 'pages/CreateShiftsPage/ShiftSummaryView';
import { QUERY_communityCustomFields, QUERY_fetchCommunityBiddingPeriod } from 'queries';
import { ComponentMapType } from 'types/common.types';
import {
  communityCustomFields,
  communityCustomFields_fetchCustomFields_nodes,
  fetchCommunityBiddingPeriod,
  fetchCommunityBiddingPeriodVariables,
  fetchCommunityBiddingPeriod_fetchCommunity,
} from 'types/graphql-types';
import i18next from 'i18next';
import { totalHoursFromDate } from 'utils/time';

export const createShiftsComponentMap: ComponentMapType = {
  CREATE_SHIFTS: BulkCreateShiftView,
  SHIFTS_SUMMARY: ShiftSummaryView,
};

export const createShiftsPageInitialState: ICreateShiftsPageContext = {
  groupedFormsBehaviors: {
    groupedForms: [getInitialCreateShiftForm({})],
    values: getInitialCreateShiftsPageForm({}),
    apiErrors: null,
    onSubmit: async () => {
      return {
        payload: {},
      };
    },
    onChange: () => {},
    addFormItem: () => {},
    removeFormItem: () => {},
    isSubmitDisabled: true,
    isSubmitting: false,
    forceValidateForm: () => {},
    groupedFormsFieldErrors: [],
    groupedFormsFormErrors: [],
    isDirty: false,
    resetGroupedForms: () => null,
  },
  biddingPeriods: {} as BiddingPeriodsType,
  values: {},
  onConfirmBooking: () => {},
  onChange: () => {},
  view: 'CREATE_SHIFTS',
  goToView: () => {},
  isLoading: false,
  handleDiscardChangesDialogAction: () => null,
  handleDiscardChangesDialogCancel: () => null,
  isDiscardChangesDialogOpen: false,
};

interface BiddingPeriodsType {
  fetchCommunity: fetchCommunityBiddingPeriod_fetchCommunity;
}

export const getBiddingPeriod = (
  startTime: string,
  biddingPeriods: BiddingPeriodsType | null,
): string => {
  const totalHours = totalHoursFromDate(startTime);

  let result = 0;
  let timeFormat = '';

  if (
    biddingPeriods?.fetchCommunity?.biddingTimes &&
    biddingPeriods?.fetchCommunity?.biddingTimes?.length
  ) {
    const { biddingTimes } = biddingPeriods.fetchCommunity;

    const biddingTimesLeadTimesSorted = [...biddingTimes];

    biddingTimesLeadTimesSorted.sort((a, b) => {
      if (b.leadTime === a.leadTime) return b.priorityTime - a.priorityTime;
      return b.leadTime - a.leadTime;
    });

    // if total hours is greater than the highest lead time, return the highest priority time
    if (totalHours >= biddingTimesLeadTimesSorted[0].leadTime) {
      result = biddingTimesLeadTimesSorted[0].priorityTime;
    }

    biddingTimesLeadTimesSorted.forEach((biddingPeriod) => {
      if (totalHours < biddingPeriod.leadTime) {
        result = biddingPeriod.priorityTime;
      }
    });
    if (result < 60) {
      timeFormat = `${result} mins`;
    } else {
      const totalTimeInHours = result / 60;
      const roundedTime = Math.round(totalTimeInHours);

      timeFormat = `${roundedTime} hours`;
    }
  }
  return timeFormat;
};

export const createShiftsPageCommunityCustomFields = ({
  byFacilityId,
}: {
  byFacilityId?: number;
}): UseGQLQueryConfig<communityCustomFields, communityCustomFields_fetchCustomFields_nodes[]> => {
  return {
    key: 'CREATE_SHIFT_MODAL_COMMUNITY_CUSTOM_FIELDS',
    query: QUERY_communityCustomFields,
    variables: {
      filter: {
        withCommunity: [byFacilityId],
      },
    },
    responseTransformer: (data) => {
      return data?.fetchCustomFields?.nodes || [];
    },
  };
};

export const communityBiddingPeriodConfig: (
  locationId: number,
) => UseGQLQueryConfig<
  fetchCommunityBiddingPeriod,
  fetchCommunityBiddingPeriod,
  fetchCommunityBiddingPeriodVariables
> = (locationId) => {
  return {
    key: 'FETCH_COMMUNITY_BIDDING_PERIOD_QUERY',
    query: QUERY_fetchCommunityBiddingPeriod,
    variables: {
      locationId,
    },
  };
};

export const createShiftsCardErrorMessages: CreateShiftsCardErrorMessagesType = {
  POSITION_REQUIRED: () => i18next.t('errors:SELECT_POSITION'),
  SHIFT_DATE_REQUIRED: () => i18next.t('errors:SELECT_DATE'),
  SHIFT_START_TIME_IN_PAST: () => i18next.t('errors:SHIFT_CANNOT_BE_IN_PAST'),
  PAID_DURATION_LOW: () => i18next.t('errors:SHIFT_DURATION_MINIMUM_1_HOUR'),
  BREAK_TIME_LONGER_THAN_DURATION: () => i18next.t('errors:SELECT_SHORTER_BREAK_TIME'),
  UNPAID_BREAK_TIME_REQUIRED: () => i18next.t('errors:SELECT_UNPAID_BREAK_TIME'),
};
