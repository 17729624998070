import { QUERY_positionsPriceRanges } from 'queries';
import {
  positionsPriceRanges,
  positionsPriceRangesVariables,
  positionsPriceRanges_fetchPositions_edges_node,
} from 'types/graphql-types';
import {
  ITeamMembersQueryProps,
  IPositionsPriceRangesQueryProps,
  PositionsPriceRangesType,
} from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.types';
import { QUERY_teamMembersCount } from 'queries';
import { teamMemberCount } from 'types/graphql-types';
import { castStringToInteger } from 'utils';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';

export const teamMembersQueryCountConfig = ({
  byFacilityId,
  byPositionId,
  isDedicated,
  onSuccess,
  byStartTime,
  byEndTime,
  allowOvertime,
}: ITeamMembersQueryProps): UseGQLQueryConfig<
  teamMemberCount,
  number,
  {
    byPositionId: number;
    byFacilityId: number;
    isDedicated?: boolean;
    byStartTime: string;
    byEndTime: string;
    allowOvertime?: boolean;
  }
> => ({
  key: 'CREATE_SHIFT_MODAL_REQUEST_TEAM',
  query: QUERY_teamMembersCount,
  responseTransformer: (data) => {
    return data?.requestJanes?.totalCount;
  },
  variables: {
    byPositionId: castStringToInteger(byPositionId[0]),
    byFacilityId,
    isDedicated,
    byStartTime,
    byEndTime,
    allowOvertime,
  },
  onSuccess,
});

export const positionsPriceRangesQueryConfig = ({
  positionIds,
  communityId,
  onSuccess,
}: IPositionsPriceRangesQueryProps): UseGQLQueryConfig<
  positionsPriceRanges,
  PositionsPriceRangesType,
  positionsPriceRangesVariables
> => ({
  key: 'POSITION_PRICE_RANGE_QUERY',
  query: QUERY_positionsPriceRanges,
  responseTransformer: (data) => {
    if (!data) return data;
    if (data?.fetchPositions?.edges) {
      return data.fetchPositions.edges.reduce((acc, positionDetailsEdge) => {
        const positionDetails: positionsPriceRanges_fetchPositions_edges_node | undefined | null =
          positionDetailsEdge?.node;
        return {
          ...acc,
          ...(positionDetails?.id && { [positionDetails?.id]: positionDetails }),
        };
      }, {});
    }
  },
  variables: {
    filter: {
      byId: positionIds.map((id) => castStringToInteger(id)),
    },
    communityId,
  },
  onSuccess,
});

export const fieldsThatAffectRequestedTeamMembers = [
  'position_id',
  'quantity',
  'dedicated',
  'allow_overtime',
];
