import { Flex } from 'components/Flex';
import {
  NavigationButton,
  NavigationButtonsWrapper,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import {
  DetailViewActiveTabType,
  DetailViewComponentMap,
  IDetailViewComponentProps,
} from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { css } from 'styled-components';

import { ConfirmAvailabilityModal } from 'components/ShiftModal/src/views/DetailView/ConfirmAvailabilityModal/ConfirmAvailabilityModal';
import { usePathname } from 'hooks/usePathname';
import { useSelector } from 'react-redux';
import isUserPremiumSelector from 'store/selectors/userSelectors/isUserPremiumSelector';
import { OrderStatusEnum } from 'types/graphql-types';
import { PATH_DETAIL_VIEW_TAB_MAP } from '../DetailView.constants';
import { AuditTrailDetailTabViewFooter } from 'components/ShiftModal/src/views/DetailView/AuditTrailDetailView/AuditTrailDetailTabViewFooter';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';

const tabs: [string, DetailViewActiveTabType][] = [
  ['General', 'GeneralTab'],
  ['Activity', 'AuditTrailActivityTab'],
  ['Timesheets', 'TimesheetsTab'],
];

export const AuditTrailDetailTabView: FC<IDetailViewComponentProps> = ({ data }) => {
  const { t, i18n } = useTranslation();
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState<DetailViewActiveTabType>(
    PATH_DETAIL_VIEW_TAB_MAP(pathname),
  );
  const ActiveTabComponent = DetailViewComponentMap[activeTab];
  const isUserPremium = useSelector(isUserPremiumSelector);

  const { timeZone } = getCurrentlySelectedLocation();

  return (
    <Fragment>
      <ConfirmAvailabilityModal />
      <Flex
        flexDirection="column"
        height="fit-content"
        minHeight="fit-content"
        css={css`
          position: sticky;
          background-color: white;
          z-index: 1;
          top: 0;
        `}
      >
        <NavigationButtonsWrapper>
          {tabs
            .filter(([, key]) => {
              if (key === 'TimesheetsTab') {
                switch (data.fetchOrder!.status.value) {
                  case OrderStatusEnum.completed:
                  case OrderStatusEnum.absent:
                    // For Premium users, show timesheets tab only if shift was done by agency jane
                    if (isUserPremium) {
                      return (
                        !!data.fetchOrder!.mainJane!.agencyCommunity &&
                        data.fetchOrder!.sentToAgencies
                      );
                    }
                    return true;
                  default:
                    return false;
                }
              }
              return true;
            })
            .map(([label, _enum]) => {
              const translatedLabel = label.toUpperCase();
              return (
                <NavigationButton
                  key={label}
                  isDisabled={!!data.fetchOrder!.isAway && label === 'Activity'}
                  onClick={() => activeTab !== _enum && setActiveTab(_enum)}
                  isActive={activeTab === _enum}
                >
                  {i18n.exists(`shift_modal:${translatedLabel}`)
                    ? t(`shift_modal:${translatedLabel}`)
                    : label}
                </NavigationButton>
              );
            })}
        </NavigationButtonsWrapper>
      </Flex>

      <ActiveTabComponent
        timeZone={timeZone}
        activities={data?.fetchOrder.activities}
        data={data}
      />

      {activeTab !== 'TimesheetsTab' && <AuditTrailDetailTabViewFooter />}
    </Fragment>
  );
};
