import { QuerySwitch } from 'components/QuerySwitch';
import { FC } from 'react';
import { TimesheetsTabComponent } from './TimesheetsTab';
import { TimesheetsTabProvider } from './TimesheetsTabForm.context';
import {
  TimesheetsTabDataContext,
  TimesheetsTabDataProvider,
  useTimeSheetsTabDataContext,
} from './TimesheetsTabData.context';
import { NoTimesheetsAvailable } from 'components/ShiftModal/src/views/DetailView/TimesheetsTab/TimesheetsTab.styled';
import { useTranslation } from 'hooks/useTranslation';
import { ShiftModalErrorView } from 'components/ShiftModal/src/views/ShiftModalErrorView';

const Inner = () => {
  const { t } = useTranslation();
  const { data } = useTimeSheetsTabDataContext();
  const timeSheetExists = !!data.fetchTimeSheet.timeSheet;
  return timeSheetExists ? (
    <TimesheetsTabProvider>
      <QuerySwitch
        component={TimesheetsTabComponent}
        context={TimesheetsTabDataContext}
        errorComponent={ShiftModalErrorView}
      />
    </TimesheetsTabProvider>
  ) : (
    <NoTimesheetsAvailable>{t('shift_modal:NO_TEAM_SHEETS_AVAILABLE')}</NoTimesheetsAvailable>
  );
};

export const TimesheetsTab: FC = () => (
  <TimesheetsTabDataProvider>
    <QuerySwitch component={Inner} context={TimesheetsTabDataContext} />
  </TimesheetsTabDataProvider>
);
