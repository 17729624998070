import { GenericErrorMessage } from '@bookjane2/bookjane-design-library';
import { useTranslation } from 'react-i18next';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { ComponentType, FC } from 'react';

const { isWhiteLabelled } = WhiteLabelAccessor;

export const RenderError: FC<{ errorComponent?: ComponentType }> = ({
  errorComponent: ErrorView,
}) => {
  const { t } = useTranslation();
  return !!ErrorView ? (
    <ErrorView />
  ) : (
    <GenericErrorMessage
      showLogo={!isWhiteLabelled()}
      errorMessage={t('errors:SOMETHING_WENT_WRONG_TRY_REFRESHING_PAGE')}
    />
  );
};
