import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { priceFormatter, parsePrice } from '../../SendToAgenciesSummaryView.constants';
import { useFetchJaneByIdDataContext } from '../../DirectRequests/FetchJaneByJaneId.data.context';
import { ShiftPay } from '../ShiftPay';

function InternalPayComponent() {
  const { data: fetchOrderData } = useShiftModalDataContext();

  const { data } = useFetchJaneByIdDataContext();

  const janeEstimatedPayout = data.fetchJane!.positions[0]!.agencyDetails?.openRate;
  const janeEstimatedTotalPayout = priceFormatter.format(
    fetchOrderData.fetchOrder!.totalDuration! * parsePrice(janeEstimatedPayout),
  );

  return (
    <ShiftPay
      estimatedHourlyPay={janeEstimatedPayout}
      estimatedTotalPay={janeEstimatedTotalPayout}
    />
  );
}

export function InternalPay() {
  return <InternalPayComponent />;
}
