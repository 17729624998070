import { Flex } from '@bookjane2/bookjane-design-library';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  CallOutHeader,
  BiddingWrapper,
  CallOutViewDetailsLink,
  CallOutTitleSection,
  CallOutBodySection,
  EstimatedTimeSection,
  BKJProgressBar,
  CallOutProgressBarSection,
  CallOutHeaderText,
  CallOutHeaderWrapper,
  ProgressBold,
  ProgressText,
} from 'components/ShiftModal/src/views/DetailView/GeneralTab/CallOut.styled';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { DateTime } from 'luxon';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import { useBiddingTime } from 'hooks/useBiddingTime';
import { AllowOvertimeDialog } from 'components/AllowOvertimeDialog';
import { AutoRebidDialog } from 'components/AutoRebidDialog';
import { ContinueCalloutDialog } from 'components/ContinueCalloutDialog';
import { toastService } from 'services';
import {
  ShiftAction,
  RenderShiftActionsProps,
} from 'components/RenderShiftActions/RenderShiftActions.types';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { ShiftActionsWrapper } from 'components/ShiftCardSkeleton/ShiftCardSkeleton.styled';
import { RenderShiftActions } from 'components/RenderShiftActions';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';

export const calculateCurrentProgress = (
  shiftCreationTime: string | undefined,
  biddingTimeEndsAt: string | undefined,
  timeZone: string | undefined,
): number | undefined => {
  if (!shiftCreationTime || !biddingTimeEndsAt) return undefined;

  const shiftTime = DateTime.fromISO(shiftCreationTime).setZone(timeZone);
  const biddingEndTime = DateTime.fromISO(biddingTimeEndsAt).setZone(timeZone);
  const stepValue = (biddingEndTime.valueOf() - shiftTime.valueOf()) / 10;
  const currentTime = DateTime.now().setZone(timeZone);
  const currentProgress = Math.floor((currentTime.valueOf() - shiftTime.valueOf()) / stepValue);

  return currentProgress;
};

export interface IBiddingSectionProps {
  shiftActions: ShiftAction[];
  isShiftInfoWrapperVisible: boolean;
  ShiftActionComponent: RenderShiftActionsProps['SingleItemComponent'];
  isContinueCallOutConfirmationOpen: boolean;
  isAllowOvertimeConfirmationOpen: boolean;
  isAutoRebidConfirmationOpen: boolean;
  setIsContinueCallOutConfirmationOpen: Dispatch<SetStateAction<boolean>>;
  setIsAllowOvertimeConfirmationOpen: Dispatch<SetStateAction<boolean>>;
  setIsAutoRebidConfirmationOpen: Dispatch<SetStateAction<boolean>>;
}

export function BiddingSection(props: IBiddingSectionProps) {
  const { t } = useTranslation();
  const {
    data: { fetchOrder },
    fetch,
    refetch,
  } = useShiftModalDataContext();

  const { refreshBackgroundPage } = useShiftModalPropsContext();

  const { biddingTimeEndsAt } = fetchOrder;
  useBiddingTime({ biddingTimeEndsAt, fetch });

  const goToAuditTrailView = useCallback(
    (calloutListId: string) =>
      shiftModalRoutingService.goToView('AuditTrailView', {
        calloutListId: calloutListId,
      }),
    [],
  );

  const { timeZone } = getCurrentlySelectedLocation();

  const currentProgress = useMemo(() => {
    const progress = calculateCurrentProgress(
      fetchOrder?.createdAt,
      fetchOrder?.biddingTimeEndsAt,
      timeZone,
    );

    if (progress !== undefined) {
      return progress;
    }
  }, [fetchOrder?.biddingTimeEndsAt, fetchOrder?.createdAt, timeZone]);

  const maxStep = 10;

  const biddingStatusLabel =
    typeof currentProgress === 'number'
      ? currentProgress < maxStep
        ? t('shift_modal:BIDDING_IN_PROGRESS')
        : t('shift_modal:BIDDING_COMPLETE')
      : t('shift_modal:BIDDING_PROGRESS_NOT_AVAILABLE');

  const {
    shiftActions,
    isShiftInfoWrapperVisible,
    ShiftActionComponent,
    isContinueCallOutConfirmationOpen,
    isAllowOvertimeConfirmationOpen,
    isAutoRebidConfirmationOpen,
    setIsContinueCallOutConfirmationOpen,
    setIsAllowOvertimeConfirmationOpen,
    setIsAutoRebidConfirmationOpen,
  } = props;

  return (
    <BiddingWrapper>
      <ContinueCalloutDialog
        orderId={fetchOrder?.id}
        isContinueCallOutConfirmationOpen={isContinueCallOutConfirmationOpen}
        setIsContinueCallOutConfirmationOpen={setIsContinueCallOutConfirmationOpen}
        onSuccess={() => {
          toastService.success(t('dashboard:CALL_OUT_SUCCESSFUL_RESUMED'));
          refreshBackgroundPage();
        }}
      />
      <AllowOvertimeDialog
        orderId={fetchOrder?.id}
        isAllowOvertimeConfirmationOpen={isAllowOvertimeConfirmationOpen}
        setIsAllowOvertimeConfirmationOpen={setIsAllowOvertimeConfirmationOpen}
        onSuccess={() => {
          toastService.success(t('dashboard:ALLOW_OVERTIME_SUCCESS'));
          refreshBackgroundPage();
        }}
      />
      <AutoRebidDialog
        orderId={fetchOrder?.id}
        isAutoRebidConfirmationOpen={isAutoRebidConfirmationOpen}
        setIsAutoRebidConfirmationOpen={setIsAutoRebidConfirmationOpen}
        onSuccess={() => {
          toastService.success(t('success:SHIFT_BIDDING_SUCCESSFULLY_AUTO_EXTENDED'));
          refreshBackgroundPage();
          if (refetch) refetch();
        }}
      />
      <CallOutTitleSection>
        <Flex flexDirection="row" flex="1">
          <CallOutHeader>
            <CallOutHeaderWrapper>
              <CallOutHeaderText>{biddingStatusLabel}</CallOutHeaderText>
            </CallOutHeaderWrapper>
          </CallOutHeader>
          {!!fetchOrder.currentlyActiveCalloutList?.id && (
            <CallOutViewDetailsLink
              onClick={() => goToAuditTrailView(fetchOrder.currentlyActiveCalloutList!.id)}
            >
              {t('shift_modal:VIEW_DETAILS')}
            </CallOutViewDetailsLink>
          )}
        </Flex>
      </CallOutTitleSection>
      <CallOutProgressBarSection>
        <BKJProgressBar value={currentProgress} max={maxStep} height="8px;"></BKJProgressBar>
      </CallOutProgressBarSection>
      <CallOutBodySection>
        <Flex flexDirection="row" flex="1">
          {fetchOrder.biddingTimeEndsAt && (
            <EstimatedTimeSection>
              <ProgressBold>{t('shift_modal:BIDDING_ENDS')}:&nbsp;</ProgressBold>
              <ProgressText>
                {DateTime.fromISO(fetchOrder.biddingTimeEndsAt)
                  .setZone(timeZone)
                  .toFormat(`EEE, MMM d 'at' h:mm a`)}
              </ProgressText>
            </EstimatedTimeSection>
          )}
        </Flex>
        {isShiftInfoWrapperVisible && (
          <ShiftActionsWrapper
            padding="24px 0 8px 0"
            justifyContent="end"
            onClick={(e) => e.stopPropagation()}
            width="180px"
            margin="0 0 0 auto"
          >
            <UserGuard
              userType={[
                UserPermissionEnum.USER_TYPE_B2B,
                UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
                UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
              ]}
            >
              {!!shiftActions.length && (
                <RenderShiftActions
                  variant="PurpleSolid"
                  shiftActions={shiftActions}
                  SingleItemComponent={ShiftActionComponent}
                />
              )}
            </UserGuard>
          </ShiftActionsWrapper>
        )}
      </CallOutBodySection>
    </BiddingWrapper>
  );
}
