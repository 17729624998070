import styled from 'styled-components';

export const ShiftUnavailableViewTitle = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints.Mobile}) {
    font-size: 18px;
  }
`;

export const ShiftUnavailableViewText = styled.span`
  font-size: 14px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 21px;
`;
