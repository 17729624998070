import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import {
  ShiftUnavailableViewText,
  ShiftUnavailableViewTitle,
} from 'components/ShiftModal/src/views/ShiftModalErrorView/ShiftModalErrorView.styled';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment, useCallback } from 'react';
import { useShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.props.context';

export const ShiftModalErrorView: FC = () => {
  const { t } = useTranslation();
  const { refreshBackgroundPage } = useShiftModalPropsContext();

  const closeRefreshShiftModal = useCallback(() => {
    refreshBackgroundPage(true);
    shiftModalRoutingService.close();
  }, [refreshBackgroundPage]);

  return (
    <Fragment>
      <Flex height="100%" padding="40px 32px 0px 32px" flexDirection="column">
        <ShiftUnavailableViewTitle>
          {t('errors:SHIFT_UNAVAILABLE_VIEW_TITLE')}
        </ShiftUnavailableViewTitle>
        <Flex flexDirection="column" gap="18px">
          <ShiftUnavailableViewText>
            {t('errors:SHIFT_UNAVAILABLE_PLEASE_CHECK_BACK')}
          </ShiftUnavailableViewText>
        </Flex>
      </Flex>
      <BKJSideModalFooter justifyContent="end">
        <BKJButton width="192px" variant="PurpleSolid" onClick={closeRefreshShiftModal}>
          {t('CLOSE_AND_REFRESH')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
