import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { QuerySwitch } from 'components/QuerySwitch';
import { DashboardEmptyView } from 'pages/DashboardPage/DashboardEmptyView';
import { DashboardPageContext } from 'pages/DashboardPage/DashboardPage.context';
import {
  DashboardPageDateGroupText,
  DashboardPageDateGroupWrapper,
  DashboardPageInProgress,
} from 'pages/DashboardPage/DashboardPage.styled';
import { GroupOrdersComponent } from 'pages/DashboardPage/GroupOrdersComponent/GroupOrdersComponent';
import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { MountTransition } from 'routes/MountTransition';
import { GQLAPIContextType, isOrderStatusInProgress } from 'types/common.types';
import { dashboardUpcomingShiftsPage } from 'types/graphql-types';
import { parseDate } from 'utils';
import { isDateToday } from 'utils/isDateToday';
import { withMemo } from 'utils/withMemo';
import { useDashboardPageUnmountContext } from 'pages/DashboardPage/DashboardPageUnmount.context';
import { queryRefetchService } from 'services/QueryRefetchService';

const DashboardUpcomingShiftsPageList: FC<GQLAPIContextType<dashboardUpcomingShiftsPage>> = ({
  data,
  loadMore,
}) => {
  const { t } = useTranslation();

  const { unmountDashboard } = useDashboardPageUnmountContext();

  useEffect(() => {
    queryRefetchService.addDataQuery('DASHBOARD_PAGE', 'upcoming', unmountDashboard);
    return () => {
      queryRefetchService.removeDataQuery('DASHBOARD_PAGE', 'upcoming');
    };
  }, [unmountDashboard]);

  if (!data) return null;
  if (!data?.fastGroupOrders) return null;
  if (!data?.fastGroupOrders?.nodes) return null;
  if (data?.fastGroupOrders?.nodes.length === 0 && !data?.fastGroupOrders?.pageInfo.hasNextPage)
    return <DashboardEmptyView />;

  return (
    <Fragment>
      {data?.fastGroupOrders?.nodes.map((record, groupIndex) => {
        const { group: date, orders } = record;
        return (
          orders.length > 0 && (
            <DashboardPageDateGroupWrapper key={date}>
              <DashboardPageDateGroupText>
                {isDateToday(date) && `${t('dashboard:TODAY')} • `}
                {`${parseDate(date, 'dd, yyyy')}`}
              </DashboardPageDateGroupText>
              {isOrderStatusInProgress(orders[0]?.status.value) && (
                <DashboardPageInProgress>{t('common:IN_PROGRESS')}</DashboardPageInProgress>
              )}
              {orders.filter(Boolean).map((order, orderIndex) => (
                <MountTransition key={order.id}>
                  <GroupOrdersComponent groupIndex={groupIndex} orderIndex={orderIndex} />
                </MountTransition>
              ))}
            </DashboardPageDateGroupWrapper>
          )
        );
      })}

      {!!data.fastGroupOrders.pageInfo.hasNextPage ? (
        <InfiniteScrollLoading onViewportEnter={loadMore} />
      ) : null}
    </Fragment>
  );
};
export const DashboardUpcomingShiftsPage: FC = withMemo(() => {
  return <QuerySwitch context={DashboardPageContext} component={DashboardUpcomingShiftsPageList} />;
});
