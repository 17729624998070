import { FC, createContext, useContext, useMemo, useCallback } from 'react';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { IShiftModalPropsContext } from 'components/ShiftModal/ShiftModal.types';
import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { getCurrentlySelectedLocation } from 'utils/getCurrentlySelectedLocation';
import {
  isMultiLocationCallOutFeatureEnabledSelector,
  isMultiLocationSchedulingFeatureEnabledSelector,
} from 'store/selectors/featureFlagSelectors';
import { useSelector } from 'react-redux';
import { getIsJaneCommunityMismatch } from 'utils/getIsJaneCommunityMismatch';
import { useLocation } from 'react-router-dom';
import { PAGE_URLS } from 'components/BKJMainNavigation/BKJMainNavigation.constants';
import { queryRefetchService } from 'services/QueryRefetchService';
import { defaultApolloClient } from 'providers/ApolloProvider';

export const ShiftModalPropsContext = createContext<IShiftModalPropsContext>(
  {} as IShiftModalPropsContext,
);

export const ShiftModalPropsProvider: FC = ({ children }) => {
  const { data } = useShiftModalDataContext();
  const { pathname } = useLocation();
  const { shift_id: orderId } = useShiftModalUIContext();

  const currentlySelectedLocation = getCurrentlySelectedLocation();

  const isMultiLocationCallOutFeatureEnabled = useSelector(
    isMultiLocationCallOutFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );
  const isMultiLocationSchedulingFeatureEnabled = useSelector(
    isMultiLocationSchedulingFeatureEnabledSelector(Number(currentlySelectedLocation?.value)),
  );

  const isSharedJanePrimaryCommunityDifferentFromSelectedLocation = useMemo(() => {
    if (data) {
      const { acceptedBySharedTeamMember, mainJane, status } = data.fetchOrder;
      return getIsJaneCommunityMismatch({
        acceptedBySharedTeamMember,
        status,
        mainJane,
        currentlySelectedLocation,
        isMultiLocationCallOutFeatureEnabled,
        isMultiLocationSchedulingFeatureEnabled,
      });
    } else return false;
  }, [
    data,
    currentlySelectedLocation,
    isMultiLocationCallOutFeatureEnabled,
    isMultiLocationSchedulingFeatureEnabled,
  ]);

  const refreshBackgroundPage = useCallback(
    (all = false) => {
      if (pathname.startsWith(PAGE_URLS.SCHEDULE)) {
        window.scheduleUtils.forceDataUpdate();
      } else if (pathname.startsWith(PAGE_URLS.SHIFTS)) {
        defaultApolloClient.refetchQueries({ include: 'all' });
      } else if (pathname.startsWith(PAGE_URLS.DASHBOARD)) {
        if (orderId) {
          queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${orderId}`);
        }
        if (all) {
          if (pathname.startsWith(`${PAGE_URLS.DASHBOARD}/available`)) {
            queryRefetchService.refetchDataQueryByKey('DASHBOARD_PAGE', 'available');
          } else if (pathname.startsWith(`${PAGE_URLS.DASHBOARD}/myupcoming`)) {
            queryRefetchService.refetchDataQueryByKey('DASHBOARD_PAGE', 'myupcoming');
          } else if (pathname.startsWith(`${PAGE_URLS.DASHBOARD}/past`)) {
            queryRefetchService.refetchDataQueryByKey('DASHBOARD_PAGE', 'past');
          } else if (pathname.startsWith(`${PAGE_URLS.DASHBOARD}/recent`)) {
            queryRefetchService.refetchDataQueryByKey('DASHBOARD_PAGE', 'recent');
          } else if (pathname.startsWith(`${PAGE_URLS.DASHBOARD}/upcoming`)) {
            queryRefetchService.refetchDataQueryByKey('DASHBOARD_PAGE', 'upcoming');
          }
        }
      } else if (pathname.startsWith(PAGE_URLS.TIMESHEETS)) {
        if (orderId && data?.fetchOrder?.orderSchedule?.timeSheet?.id)
          queryRefetchService.refetchDataQueryByKey(
            'TIMESHEETS_CARD',
            `${data?.fetchOrder?.orderSchedule?.timeSheet?.id}`,
          );
      }
    },
    [data?.fetchOrder?.orderSchedule?.timeSheet?.id, orderId, pathname],
  );

  const contextValue = useMemo(
    () => ({
      isSharedJanePrimaryCommunityDifferentFromSelectedLocation,
      goToDeleteView: () => shiftModalRoutingService.goToView('DeleteView'),
      goToEditView: () => shiftModalRoutingService.goToView('EditView'),
      goToReleaseShiftView: () => shiftModalRoutingService.goToView('ReleaseShiftView'),
      goToSendToAgenciesView: () =>
        data?.fetchOrder?.biddable && data?.fetchOrder?.autoRebid
          ? shiftModalRoutingService.goToView('SendToAgencyConfirmationView')
          : shiftModalRoutingService.goToView('PreRequestOptionsView'),
      goToAwardTeamMemberView: () => shiftModalRoutingService.goToView('AwardTeamMemberView'),
      goToConfirmEditsView: () => shiftModalRoutingService.goToView('ConfirmEditsView'),
      refreshBackgroundPage,
    }),
    [
      data?.fetchOrder?.autoRebid,
      data?.fetchOrder?.biddable,
      isSharedJanePrimaryCommunityDifferentFromSelectedLocation,
      refreshBackgroundPage,
    ],
  );
  return (
    <ShiftModalPropsContext.Provider value={contextValue}>
      {children}
    </ShiftModalPropsContext.Provider>
  );
};

export const useShiftModalPropsContext = (): IShiftModalPropsContext =>
  useContext(ShiftModalPropsContext);
